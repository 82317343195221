import axios from 'axios'
import server from "../config/server";

axios.defaults.withCredentials=true;

const service = axios.create({
    baseURL:server.baseURL, // api的base_url
    timeout: 15000 // 请求超时时间
})


export default service