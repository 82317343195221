import request from '../utils/request'

export function getMyoldman(params) {
    return request({
        url: '/MyInfo/' + params,
        method: 'get'
    })
}

export function getMyoldmanInfo(openid, edate) {
    return request({
        url: '/MyInfo/' + openid + '/' + edate + '/info',
        method: 'get'
    })
}

export function getMonitorList(userId) {
    return request({
        url: '/MyInfo/getMonitorList/' + userId,
        method: 'get'
    })
}


export function remoteTest(deviceId, type) {
    return request({
        url: '/MyInfo/wristband/remoteTest/' + deviceId + '/' + type,
        method: 'get'
    })
}

export function getTestData(deviceId, type) {
    return request({
        url: '/MyInfo/wristband/getTestData/' + deviceId + '/' + type,
        method: 'get'
    })
}

export function getAccessToken() {
    return request({
        url: '/MyInfo/getAccessToken/',
        method: 'get'
    })
}

export function getGroupInfo(openid) {
    return request({
        url: '/MyInfo/' + openid + '/GroupInfo',
        method: 'get'
    })
}

export function getBatheList(userId) {
    return request({
        url: '/MyInfo/getBatheList/' + userId,
        method: 'get'
    })
}

export function getAvgParlor(userId) {
    return request({
        url: '/MyInfo/getAvgParlor/' + userId,
        method: 'get'
    })
}

export function getLogInfo(userId) {
    return request({
        url: '/MyInfo/getLogInfo/' + userId,
        method: 'get'
    })
}

//获取用户名下提醒状态为1的数据
export function getLogInfoStatus1(userId) {
    return request({
        url: '/MyInfo/getLogInfoStatus1/' + userId,
        method: 'get'
    })
}

export function login(u, p) {
    return request({
        url: '/MyInfo/login/u/' + u + '/p/' + p,
        method: 'post'
    })
}

export function getCustLogInfo(openId) {
    return request({
        url: '/MyInfo/getCustLogInfo/' + openId,
        method: 'get'
    })
}

export function sendCode(mobile) {
    return request({
        url: '/wx/sendShortmsg/?mobile=' + mobile,
        method: 'get'
    })
}

export function validCode(code) {
    return request({
        url: '/wx/validCode/?code=' + code,
        method: 'get'
    })
}

export function updateUserinfo(userId, data) {
    return request({
        url: '/MyInfo/updateUserinfo/' + userId,
        method: 'post',
        data: data,
    })
}

export function updateUserSet(userId, data) {
    return request({
        url: '/MyInfo/updateUserSet/' + userId,
        method: 'post',
        data: data,
    })
}

export function getUserSet(userId) {
    return request({
        url: '/MyInfo/getUserSet/' + userId,
        method: 'post',
    })
}
export function getUserLifeAnalysis(userId) {
    return request({
        url: '/MyInfo/getUserLifeAnalysis/' + userId,
        method: 'post',
    })
}

export function getUserFamilyPhone(deviceId) {
    return request({
        url: '/MyInfo/getUserFamilyPhone/' + deviceId,
        method: 'post',
    })
}

export function setUserFamilyPhone(deviceId, phone) {
    return request({
        url: '/MyInfo/setUserFamilyPhone/' + deviceId + '/' + phone,
        method: 'post',
    })
}

export function bindDevice(openId , userId , productQrCode) {
    return request({
        url: '/MyInfo/bindDevice/' + openId + '/' + userId + '/' + productQrCode,
        method: 'post',
        /*url: '/MyInfo/bindDevice',
        data = {'custPhone': custPhone , 'productQrCode': productQrCode,},
        method: 'post',*/
    })
}

export function createOrder(userId, numberOfMonths) {
    return request({
        url: '/pay/createOrder?userId=' + userId + '&numberOfMonths=' + numberOfMonths,
        method: 'get'
    })
}

export function findServiceEndTime(userId) {
    return request({
        url: '/MyInfo/findServiceEndTime/' + userId,
        method: 'get'
    })
}

export function getSign(tokenUrl) {
    return request({
        url: '/jsapi/getSign?tokenUrl=' + tokenUrl,
        method: 'get'
    })
}

export function getWXOpenId(openid) {
    return request({
        url: '/MyInfo/getWXOpenId/' + openid,
        method: 'get'
    })
}

export function countUserDevice(userId) {
    return request({
        url: '/MyInfo/countUserDevice/' + userId,
        method: 'get'
    })
}

export function getUserDevice(userId) {
    return request({
        url: '/MyInfo/getUserDevice/' + userId,
        method: 'get'
    })
}

export function getSysOpenId(sysUserAcco) {
    return request({
        url: '/MyInfo/getSysOpenId/' + sysUserAcco,
        method: 'get'
    })
}
