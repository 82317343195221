<template>
    <div id="app">
        <li v-if="oldman">
            <div class="box-item2">
                <nut-row>
                    <nut-col style="width: 100%">
                        <nut-cell style="margin-left: 1.5%;" title="服务有效期" :desc="endtime"></nut-cell>
                        <br/>
                        <div class="wrap">
                            <div class="single">
                                <ul class="box">
                                    <li
                                            v-for="(c,index) in payItem"
                                            :class="{checked:index==currentIndex}"
                                            @click="changeList(index,c)"
                                            :key="index"
                                            class="text-wrapper"
                                    >
                                        <div class="middle-text">{{c.months}}个月</div>
                                        <div class="import-text">{{c.newPrice}}元</div>
                                        <div>原价：<span class="zhx">{{c.oldPrice}}</span>元</div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div class="submit" @click="createOrder()">服务续费</div>
                        <div style="margin-top: 100%"></div>
                    </nut-col>
                </nut-row>
            </div>
        </li>
    </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>

<script type="text/javascript">
    import {createOrder, findServiceEndTime, getMyoldman} from "../api/getMyoldman"

    var paypara

    export default {
        //inject: ['reload'],   //注入依赖（在App.vue中），实现页面刷新
        data() {
            return {
                //months: ["3个月\n90元\n原价：105元", "6个月\n170元\n原价：210元", "12个月\n330元\n原价：420元"],
                payItem: [
                    {months: '3',newPrice: '0.01',oldPrice: '105'},
                    {months: '6',newPrice: '170',oldPrice: '210'},
                    {months: '12',newPrice: '330',oldPrice: '420'}
                ],
                currentIndex: 0,

                myoldman: [{}],
                oldman:null,

                paypara:{},
                paypara:null,

                endtime:[{}],
                endtime:null,
            };
        },
        components: {},
        created() {
            //window.ts.Toast.text("openid："+sessionStorage.getItem('openId'));
            //获取老人信息
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                this.oldman=this.myoldman[sessionStorage.getItem("ind")?sessionStorage.getItem("ind"):0]
                sessionStorage.setItem("userId", this.oldman.userId)
                //window.ts.Toast.text("userid:"+this.oldman.userId);
                //获取服务到期时间
                findServiceEndTime(this.oldman.userId).then(res2 => {
                    this.endtime = res2.data.data
                    window.ts.Toast.text("endtime:"+endtime)
                })
            })
        },

        methods: {
            changeList(index,c) {
                this.currentIndex= index;
                console.log(c)
            },
            createOrder() {
                //debugger;
                if (this.oldman.userId == '') {
                    window.ts.Toast.text("用户信息有误");
                } else {
                    //debugger;
                    //window.ts.Toast.text("userid:"+this.oldman.userId);
                    //window.ts.Toast.text("months:"+this.payItem[this.currentIndex].months);
                    //创建支付订单
                    createOrder(this.oldman.userId, this.payItem[this.currentIndex].months).then(res => {
                        if (res.data.code == 200) {
                            //debugger;
                            //window.ts.Toast.text("appId:"+res.data.data.appId);
                            paypara = res.data.data
                            //this.paypara = this.mypaypara[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                            //window.ts.Toast.text("appId:"+this.paypara.appId)
                            paypara.appId = res.data.data.appId
                            paypara.timeStamp = res.data.data.timeStamp
                            paypara.nonceStr = res.data.data.nonceStr
                            paypara.package = res.data.data.package
                            paypara.signType = res.data.data.signType
                            paypara.paySign = res.data.data.paySign
                            //window.ts.Toast.text("appId:"+this.paypara.appId);
                            if (typeof WeixinJSBridge == "undefined") {   //微信浏览器内置对象。参考微信官方文档
                                //window.ts.Toast.text("test01");
                                if (document.addEventListener) {
                                    //window.ts.Toast.text("test02");
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    //window.ts.Toast.text("test03");
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                //调用微信支付
                                onBridgeReady();
                            }
                            /*
                            window.location.href = "/";
                            window.ts.Toast.text("openid："+sessionStorage.getItem('openId'));*/
                        } else {
                            window.ts.Toast.text(res.data.message)
                        }
                    })
                }
            }
        }
    };

    function onBridgeReady() {
        //window.ts.Toast.text("test2");
        //window.ts.Toast.text("appId:"+paypara.appId);
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": paypara.appId,     //公众号ID，由商户传入
                "timeStamp": paypara.timeStamp,     //时间戳，自1970年以来的秒数
                "nonceStr": paypara.nonceStr,      //随机串
                "package": paypara.package,
                "signType": paypara.signType,     //微信签名方式：
                "paySign": paypara.paySign //微信签名
                /*"appId": "wx2acdd9f5fc0a62b7",     //公众号ID，由商户传入
                "timeStamp": "1626575767",     //时间戳，自1970年以来的秒数
                "nonceStr": "nnXDZLt1fwWRHQ0d",      //随机串
                "package": "prepay_id=wx18103606725243d06880c9eabf1b5c0000",
                "signType": "MD5",     //微信签名方式：
                "paySign": "92548ADCC2F26F4BAA2F798730F325B0" //微信签名*/
            },
            function(res) {
                //window.ts.Toast.text("test3");
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    window.ts.Toast.text("续费成功");
                    //刷新页面
                    //this.reload();
                    location.reload();
                }
            });
    }
</script>



<style lang="less" scoped>

    .wrap {
        padding: 0;
        margin: 0;
        // 单选
        .single {
            ul.box {
                margin: 0;
                padding: 0;
                list-style: none;
                margin: 0px;   //外边框
                li {
                    width: 28.5%;
                    height: 100px;
                    display: inline-block;
                    border-radius: 8px;   //边角弧度
                    border: 1px #000 solid;
                    text-align: center;
                    line-height: 33px;    //文本高度
                    cursor: pointer;
                    transition: all 0.3s linear;
                    margin-left: 13px;   //左侧距离，可通过这个调整间距
                    &.checked {
                        //background-color: #0cf;
                        //color: #fff;
                        border: 3px #fd7d3c solid;   //选中后边框线条粗细和颜色改变
                    }
                }
            }
        }
    }

    .zhx{
        text-decoration:line-through;
    }


</style>